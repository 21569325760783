@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

// Set your brand colors
// $primary: #ed008b;
// $primary2: #259c90;
$primary: #000000;
$primary2: #e6282c;
$secondary: #020202;
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
$subtitle-color: black;

$tabs-toggle-link-active-background-color: $primary2;
$tabs-toggle-link-active-border-color: $primary2;

// Import only what you need from Bulma
@import "../node_modules/bulma/bulma.sass";

// TODO: not sure if we need this here or not or want to remove it from the component
// @import 'react-big-calendar/lib/sass/styles';

.has-white-text {
	color: white !important;
}

.article-button {
	background-color: #7cc4bc;
	border: 1px solid #7cc4bc;
}

.drop-box {
	min-height: 100px;
}

.is-primary-color {
	color: $primary;
}

.is-primary2-color-icon {
	color: $primary2;
	border-color: $primary2;
}

.is-primary-color-underline {
	border-color: $primary;
	border-top-width: 0px;
	border-right-width: 0px;
	border-left-width: 0px;
	border-bottom-width: 2px;
}

.is-primary-border {
	border-color: $primary;
	border-width: 2px;
}

// custom button border tech
.is-primary-button {
	border-color: $primary;
	border-width: 2px;
	color: $primary;
}

.is-primary-button:hover {
	border-color: $primary2;
	border-width: 2px;
	color: $primary2;
}

.is-primary-button:focus {
	border-color: $primary;
	border-width: 2px;
	color: $primary;
}

.is-primary2-color {
	color: $primary2;
}

.is-primary2-color-underline {
	border-color: $primary2;
	border-top-width: 0px;
	border-right-width: 0px;
	border-left-width: 0px;
	border-bottom-width: 2px;
}

.is-primary2-border {
	border-color: $primary2;
	border-width: 2px;
}

// custom button border tech
.is-primary2-button {
	border-color: $primary2;
	border-width: 2px;
	color: $primary2;
}

.is-primary2-button:hover {
	border-color: $primary;
	border-width: 2px;
	color: $primary;
}

.is-primary2-button:focus {
	border-color: $primary2;
	border-width: 2px;
	color: $primary2;
}

.header-hr {
	background-color: $primary2;
	height: 2px;
	margin-top: 4px;
	margin-bottom: 4px;
}

.login-hr {
	background-color: $primary2;
}

.login-box-border {
	border: 1px solid;
	padding: 10px;
	box-shadow: 5px 10px $primary2;
}

.is-small-container {
	margin-left: 10px;
	margin-right: 10px;
}

.is-full-width {
	width: 100% !important;
}

.table_wrapper {
	overflow-x: auto;
}

.is-align-center {
	text-align: center;
}

.is-margin-left {
	margin-left: 10px;
}

.is-margin-right {
	margin-right: 10px;
}

.is-margin-top {
	margin-top: 20px;
}

.is-primary-bg {
	background: $primary;
}

.is-primary-txt {
	color: $primary;
}

.is-primary2-txt {
	color: $primary2;
}

.nav-hamburger {
	background: $primary;
	border-color: $primary;
	height: 4.25rem;
}
.control-label {
	margin-left: 0px;
	padding-left: 0px;
	text-align: left;
}

//FORM SCSS, TODO: MOVE TO FORM FOLDER//
.form-array {
	padding: 20px;
}
.form-dropdown {
	margin-top: 10px;
}
.form-input {
	margin-top: 10px;
}
.form-checkbox {
	margin-top: 10px;
}
.add-button {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-right: 5px;
	margin-left: 5px;
}
.remove-button {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-right: 5px;
	margin-left: 5px;
}
.table-refferal-header {
	text-align: center !important;
}
.table-refferal-item {
	text-align: center !important;
}

.table-ingest-item {
	word-wrap: break-word !important;
	// text-overflow: ellipsis !important;
}
.truncate {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.toolbarClassname {
	text-align: right;
	margin-bottom: 8px;
}

.dialogContainerClassname {
	position: absolute;
	inset: 0;
	display: flex;
	place-items: center;
	background: rgba(0, 0, 0, 0.1);
	> dialog {
		width: 300px;
		> input {
			width: 100%;
		}
		> menu {
			text-align: right;
		}
	}
}

.textEditorClassname {
	appearance: none;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0px 6px 0 6px;
	border: 2px solid #ccc;
	vertical-align: top;
	color: var(--color);
	background-color: var(--background-color);
	font-family: inherit;
	font-size: var(--font-size);
	&:focus {
		border-color: var(--selection-color);
		outline: none;
	}
	&::placeholder {
		color: #999;
		opacity: 1;
	}
}

.navbar-item:hover {
	text-decoration: none !important;
}

.no-underline {
	text-decoration: none !important;
}

.navlink-default-text {
	font-size: large !important;
}

.is-margin-left {
	margin-left: 10px !important;
}